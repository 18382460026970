/**
* Generated automatically at built-time (2024-12-10T09:53:23.710Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "udekokken-beskyttelsesovertraek-custom-made",templateKey: "sites/103-8002a236-ec0d-4a82-9cbc-1b8051f41dac"};